export interface VersionReleaseNotesModeModalProps {
  version: string;
  releaseNotes: string[];
}

export const initVersionReleaseNotesModalProperties: VersionReleaseNotesModeModalProps =
  {
    version: '',
    releaseNotes: [],
  };
