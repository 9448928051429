import type {
  FlowType,
  ParamJSONData,
  PluginInputParams,
  PluginMethod,
  PluginOutputParams,
  PluginParamsInput,
  PluginProtocol,
  PluginType,
  PluginsModuleJSON,
} from '@kiroboio/fct-core';
import type { Edge, Node, NodeProps } from '@kiroboio/reactflow';

export interface PluginNodeData {
  protocol: PluginProtocol;
  type: PluginType | 'LIBRARY' | 'LIBRARY:VIEWONLY' | 'INFORMATION';
  name: string;
  method: PluginMethod;
  methodInterface: string;
  methodInterfaceHash: string;
  input: PluginParamsInput<PluginInputParams>;
  output: PluginParamsInput<PluginOutputParams>;
  inputList: ParamJSONData[];
  outputList: ParamJSONData[];
  inputWithMeta: PluginParamsInput<PluginInputParams>;
  outputWithMeta: PluginParamsInput<PluginOutputParams>;
  contractInterface: string;
  contractInterfaceReturns: string;
  id?: string;
  moduleId?: string;
  multicallData?: string;
  moduleData?: PluginsModuleJSON;
  pluginType?: string;
  pluginsAmount?: number;
  _flowType?: FlowType;
}

type NodeId = string;
type HandleId = string;

export type CustomizedGroupNodeParameter = {
  label?: string;
  description?: string;
  readonly?: boolean;
  hidden?: Record<HandleId, boolean>;
  multi?: boolean;
  multiParamColumnSize?: Record<HandleId, number>;
  prefix?: Record<HandleId, string>;
  suffix?: Record<HandleId, string>;
  showLoading?: Record<HandleId, boolean>;
};

export type GroupNodeData = {
  parent?: string;
  type: 'group';
  name: string;
  nodeIds: string[];
  intent?: Edge[];
  multiIntent?: Edge[];
  endNode?: string;
  amountOfCalls?: string;
  in?: { nodeId?: string };
  out?: string[];
  customizedParameters: Record<
    NodeId,
    Record<HandleId, CustomizedGroupNodeParameter>
  >;
};

export type GroupIOMarkerData = {
  groupId: string;
};

export type NodeData = PluginNodeData | GroupNodeData | GroupIOMarkerData;

export type FCTGroupNode = FCTNode<GroupNodeData>;
export type FCTGroupIOMarkerNode = FCTNode<GroupIOMarkerData>;
export type FCTPluginNode = FCTNode<PluginNodeData>;

export type FCTNode<T extends NodeData = NodeData> = Node<T> & {
  invisible?: boolean;
};
export type FCTNodeProps = NodeProps<NodeData>;
export type NodeState = Pick<
  FCTNode,
  | 'selectable'
  | 'dragging'
  | 'selected'
  | 'resizing'
  | 'connectable'
  | 'deletable'
  | 'draggable'
  | 'focusable'
>;

export const isComputeVariableNode = (type?: any) => {
  return type === 'COMPUTED';
};

export const isValidateVariableNode = (type?: any) => {
  return type === 'VALIDATION';
};

export const isPluginNodeData = (data?: any): data is PluginNodeData => {
  return (
    data?.type !== undefined &&
    data?.method !== undefined &&
    data?.methodInterface !== undefined
  );
};

export const isPluginNode = (node?: any): node is FCTPluginNode => {
  if (!node) return false;
  return isPluginNodeData(node.data);
};

export const isGroupNodeData = (data?: any): data is GroupNodeData => {
  return data?.type === 'group';
};

export const isGroupNode = (node?: any): node is FCTGroupNode => {
  if (!node) return false;
  return isGroupNodeData(node.data);
};

export const isGroupIOMarkerData = (data?: any): data is GroupIOMarkerData => {
  return data?.groupId !== undefined;
};

export const isGroupIOMarkerNode = (
  node?: any
): node is FCTGroupIOMarkerNode => {
  if (!node) return false;
  return isGroupIOMarkerData(node.data);
};
