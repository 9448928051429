export interface DispatchedFlowAndAppModalProps {
  mode: 'alert' | 'flow' | 'app';
  title: string;
  dataCyBody: string;
  dataCyActiveLink: string;
  monitorExecutionText: string;
  monitorExecutionHref: string;
  backToText: string;
  backToHref: string;
}

export const initDispatchedFlowAndAppModalProperties: DispatchedFlowAndAppModalProps =
  {
    mode: 'flow',
    title: '',
    dataCyBody: '',
    dataCyActiveLink: '',
    monitorExecutionText: '',
    monitorExecutionHref: '',
    backToText: '',
    backToHref: '',
  };
