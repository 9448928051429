export interface PauseActiveFlowModalProps {
  pauseState: Record<string, string>;
  isVirtualPausedExist: boolean;
}

export const initPauseActiveFlowModalProperties: PauseActiveFlowModalProps = {
  pauseState: {
    regular: '',
    virtual: '',
  },
  isVirtualPausedExist: false,
};
