export interface SimulateModalProps {
  handler: (
    autoApprovals: boolean,
    customNativeVaultAmountEther?: string,
    customNativeWalletAmountEther?: string
  ) => void;
  autoApprovalsEnable: boolean;
  customVaultBalanceEnable: boolean;
  customWalletBalanceEnable: boolean;
}

export const initSimulateModalProperties: SimulateModalProps = {
  handler: () => {},
  autoApprovalsEnable: false,
  customVaultBalanceEnable: false,
  customWalletBalanceEnable: false,
};
