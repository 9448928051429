import type { ActiveAppsItemType } from '@kiroboio/fct-sdk';

// interface APYSingle {
//   apy: string | number;
//   tvlUsd: string | number;
//   apy30dAverage: string | number;
// }

export interface APYModalProps {
  // activeAppId: string;
  activeApp: ActiveAppsItemType | undefined;
  appId: string;
  // enter: APYSingle;
  // exitHandler?: () => void;
}

export const initAPYModalProperties = {
  // activeAppId: '',
  activeApp: undefined,
  appId: '',
  // enter: {
  //   apy: 0,
  //   tvlUsd: 0,
  //   apy30dAverage: 0,
  // },
  // exitHandler: () => {},
};
