import type { AppsDefiDataItemType } from '@kiroboio/fct-sdk';

export interface DefiAppsModalProps {
  dataItem: AppsDefiDataItemType | undefined;
  defiAppsRowId: string;
  view: 'table' | 'app';
}

export const initDefiAppsModalProperties: DefiAppsModalProps = {
  dataItem: undefined,
  defiAppsRowId: '',
  view: 'table',
};
