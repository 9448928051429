export interface CreateAppVersionModalProps {
  modes?: {
    name: string;
    description: string;
    draft: any;
  }[];
  releaseNotes: string[];
  actAs: 'add' | 'duplicate';
}

export const initCreateAppVersionModalProperties: CreateAppVersionModalProps = {
  modes: [],
  releaseNotes: [],
  actAs: 'add',
};
