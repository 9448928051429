export interface ChangeVersionModeModalProps {
  index: number;
  name: string;
  description: string;
  draft: any;
}

export const initChangeVersionModeModalProperties: ChangeVersionModeModalProps =
  {
    index: 0,
    name: '',
    description: '',
    draft: {},
  };
