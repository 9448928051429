import type { AppsDefiDataItemType } from '@kiroboio/fct-sdk';

export interface YieldsAppsModalProps {
  dataItem: AppsDefiDataItemType | undefined;
  yieldsAppsRowId: string;
  view: 'table' | 'app';
}

export const initYieldsAppsModalProperties: YieldsAppsModalProps = {
  dataItem: undefined,
  yieldsAppsRowId: '',
  view: 'table',
};
