export interface PauseFlowAlertModalProps {
  pauseState: Record<string, string>;
  isVirtualPausedExist: boolean;
}

export const initPauseFlowAlertModalProperties: PauseFlowAlertModalProps = {
  pauseState: {
    virtual: '',
  },
  isVirtualPausedExist: false,
};
