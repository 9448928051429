/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
import { get, startCase } from 'lodash';

import {
  intentConfig,
  type PluginName,
  type PluginNodeData,
  type ProtocolName,
} from '@kiroboio/fct-builder';

export type InnerDep = {
  prop: 'decimals' | 'value' | 'account';
  depType: 'inner';
  originDepLabel?: string;
  dependsOn: {
    type?: 'token';
    input: string;
    prop: 'decimals' | 'value';
  };
};

export type InternalDep = {
  prop: 'value';
  depType: 'internal';
  dependsOn: {
    path: string;
    prop: 'value';
  };
};

export type AttachedTokenDep = {
  prop: 'value';
  depType: 'attached';
  originDepLabel?: string;
  dependsOn: {
    type?: 'token';
    input: string;
    prop: 'value' | 'decimals';
  };
};

export type BaseProtocol = {
  protocolName: string;
  protocolSubName?: string;
};

export const getFunctionsDescriptiveName = (
  functionPath: string,
  fallback = ''
): string => {
  return get(intentConfig, `${functionPath}.title`, fallback) as string;
};

export const getFunctionCustomName = (
  protocol: ProtocolName,
  name: PluginName
): string => {
  return startCase(intentConfig[protocol]?.names[name]?.functionName || name);
};

export const getProtocolCustomNames = (
  protocol: PluginNodeData['protocol'] | 'KIROBO_MULTICALL'
): BaseProtocol => {
  const protocolConfig = intentConfig[protocol];

  return {
    protocolName: protocolConfig?.protocolName || protocol,
    protocolSubName: protocolConfig?.protocolSubName,
  };
};

export const getMappedMethods = (protocol: ProtocolName) => {
  // get all methods for protocol
  return intentConfig[protocol];
};
