/* eslint-disable import/no-cycle */
// TODO: for tal: make refactoring to the generator hook to make optional params and etc
// this file is used to generate the hooks for modals with id and props
import { v4 as genUniqueId } from 'uuid';

import { generateModalHooks } from '~/lib/hooks/useModals/utils/generateModalHooks';

import type { APYModalProps } from './TypesAndInitials/APY';
import { initAPYModalProperties } from './TypesAndInitials/APY';
import type { AirdropModalProps } from './TypesAndInitials/Airdrop';
import { initAirdropModalProperties } from './TypesAndInitials/Airdrop';
import type { ChangeVersionModeModalProps } from './TypesAndInitials/ChangeVersionMode';
import { initChangeVersionModeModalProperties } from './TypesAndInitials/ChangeVersionMode';
import type { CopyModeToAVersionModalProps } from './TypesAndInitials/CopyModeToAVersion';
import { initCopyModeToAVersionModalProperties } from './TypesAndInitials/CopyModeToAVersion';
import type { CreateAlertModalProps } from './TypesAndInitials/CreateAlert';
import { initCreateAlertProperties } from './TypesAndInitials/CreateAlert';
import type { CreateAppVersionModalProps } from './TypesAndInitials/CreateAppVersion';
import { initCreateAppVersionModalProperties } from './TypesAndInitials/CreateAppVersion';
import type { DefiAppsModalProps } from './TypesAndInitials/DefiApps';
import { initDefiAppsModalProperties } from './TypesAndInitials/DefiApps';
import type { DeleteAppVersionModalProps } from './TypesAndInitials/DeleteAppVersion';
import { initDeleteAppVersionModalProperties } from './TypesAndInitials/DeleteAppVersion';
import type { DeleteImageModalProps } from './TypesAndInitials/DeleteImage';
import { initDeleteImageModalProperties } from './TypesAndInitials/DeleteImage';
import type { DeleteVersionModeModalProps } from './TypesAndInitials/DeleteVersionMode';
import { initDeleteVersionModeModalProperties } from './TypesAndInitials/DeleteVersionMode';
import type { DispatchedFlowAndAppModalProps } from './TypesAndInitials/DispatchedFlowAndApp';
import { initDispatchedFlowAndAppModalProperties } from './TypesAndInitials/DispatchedFlowAndApp';
import type { EditAlertModalProps } from './TypesAndInitials/EditAlert';
import { initEditAlertModalProperties } from './TypesAndInitials/EditAlert';
import type { MissingOnChainWalletModalProps } from './TypesAndInitials/MissingOnChainWallet';
import { initMissingOnChainWalletModalProperties } from './TypesAndInitials/MissingOnChainWallet';
import type { PauseActiveAppModalProps } from './TypesAndInitials/PauseActiveApp';
import type { PauseActiveFlowModalProps } from './TypesAndInitials/PauseActiveFlow';
import { initPauseActiveFlowModalProperties } from './TypesAndInitials/PauseActiveFlow';
import type { PauseFlowAlertModalProps } from './TypesAndInitials/PauseFlowAlert';
import { initPauseFlowAlertModalProperties } from './TypesAndInitials/PauseFlowAlert';
import type { PowerModalProps } from './TypesAndInitials/Power';
import { initPowerModalProperties } from './TypesAndInitials/Power';
import type { PublishModalProps } from './TypesAndInitials/Publish';
import { initPublishModalProperties } from './TypesAndInitials/Publish';
import type { SetDefaultVersionModeModalProps } from './TypesAndInitials/SetDefaultVersionMode';
import { initSetDefaultVersionModeModalProperties } from './TypesAndInitials/SetDefaultVersionMode';
import type { SettingsModalProps } from './TypesAndInitials/Settings';
import { initSettingsModalProperties } from './TypesAndInitials/Settings';
import type { SimulateModalProps } from './TypesAndInitials/Simulate';
import { initSimulateModalProperties } from './TypesAndInitials/Simulate';
import type {
  SendNftModalProps,
  SendTokenModalProps,
} from './TypesAndInitials/TokensAndNfts';
import {
  initSendNftModalProperties,
  initSendTokenModalProperties,
} from './TypesAndInitials/TokensAndNfts';
import type { VersionReleaseNotesModeModalProps } from './TypesAndInitials/VersionReleaseNotes';
import { initVersionReleaseNotesModalProperties } from './TypesAndInitials/VersionReleaseNotes';
import type { YieldsAppsModalProps } from './TypesAndInitials/YieldsApps';
import { initYieldsAppsModalProperties } from './TypesAndInitials/YieldsApps';

interface ModalProps {}

const emptyProperties = {
  init: () => ({}),
  mixins: {},
};

export const {
  useModalActions: useSendTokenModalActions,
  useActiveModal: useSendTokenStateModal,
} = generateModalHooks<SendTokenModalProps>({
  init: () => ({
    ...initSendTokenModalProperties,
  }),
  mixins: {},
  //   toggleMax: () => (prev) => ({ ...prev, max: !prev.max }),
  //   toggleBetweenWallets: () => (prev) => ({
  //     ...prev,
  //     betweenWallets: !prev.betweenWallets,
  //   }),
  // },
});

export const {
  useModalActions: useSendNftModalActions,
  useActiveModal: useSendNftStateModal,
} = generateModalHooks<SendNftModalProps>({
  init: () => ({
    ...initSendNftModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useAPYModalActions,
  useActiveModal: useAPYStateModal,
} = generateModalHooks<APYModalProps>({
  init: () => ({
    ...initAPYModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useEditAlertModalActions,
  useActiveModal: useEditAlertStateModal,
} = generateModalHooks<EditAlertModalProps>({
  init: () => ({
    ...initEditAlertModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useEditAppsAlertModalActions,
  useActiveModal: useEditAppsAlertStateModal,
} = generateModalHooks<EditAlertModalProps>({
  init: () => ({
    ...initEditAlertModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: usePauseFlowAlertModalActions,
  useActiveModal: usePauseFlowAlertStateModal,
} = generateModalHooks<PauseFlowAlertModalProps>({
  init: () => ({
    ...initPauseFlowAlertModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: usePauseAppsAlertModalActions,
  useActiveModal: usePauseAppsAlertStateModal,
} = generateModalHooks<PauseFlowAlertModalProps>({
  init: () => ({
    ...initPauseFlowAlertModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: usePauseActiveFlowModalActions,
  useActiveModal: usePauseActiveFlowStateModal,
} = generateModalHooks<PauseActiveFlowModalProps>({
  init: () => ({
    ...initPauseActiveFlowModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: usePauseActiveAppModalActions,
  useActiveModal: usePauseActiveAppStateModal,
} = generateModalHooks<PauseActiveAppModalProps>({
  init: () => ({
    ...initPauseActiveFlowModalProperties,
  }),
  mixins: {},
});

export const defiAppsSocialId = genUniqueId();
export const {
  useModalActions: useDefiAppsSocialModalActions,
  useActiveModal: useDefiAppsSocialStateModal,
} = generateModalHooks<DefiAppsModalProps>({
  init: () => ({
    ...initDefiAppsModalProperties,
  }),
  mixins: {},
});

export const defiAppsId = genUniqueId();
export const {
  useModalActions: useDefiAppsModalActions,
  useActiveModal: useDefiAppsStateModal,
} = generateModalHooks<DefiAppsModalProps>({
  init: () => ({
    ...initDefiAppsModalProperties,
  }),
  mixins: {},
});

export const yieldsAppsSocialId = genUniqueId();
export const {
  useModalActions: useYieldsAppsSocialModalActions,
  useActiveModal: useYieldsAppsSocialStateModal,
} = generateModalHooks<YieldsAppsModalProps>({
  init: () => ({
    ...initYieldsAppsModalProperties,
  }),
  mixins: {},
});

export const yieldsAppsId = genUniqueId();
export const {
  useModalActions: useYieldsAppsModalActions,
  useActiveModal: useYieldsAppsStateModal,
} = generateModalHooks<YieldsAppsModalProps>({
  init: () => ({
    ...initYieldsAppsModalProperties,
  }),
  mixins: {},
});

export const tempIndicatorDefiAppsId = genUniqueId();
export const {
  useModalActions: useTempIndicatorDefiAppsModalActions,
  useActiveModal: useTempIndicatorDefiAppsStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const publishId = genUniqueId();
export const {
  useModalActions: usePublishModalActions,
  useActiveModal: usePublishStateModal,
} = generateModalHooks<PublishModalProps>({
  init: () => ({
    ...initPublishModalProperties,
  }),
  mixins: {},
});

export const simulateId = genUniqueId();
export const {
  useModalActions: useSimulateModalActions,
  useActiveModal: useSimulateStateModal,
  useModalProps: useSimulateModalProps,
} = generateModalHooks<SimulateModalProps>({
  init: () => ({
    ...initSimulateModalProperties,
  }),
  mixins: {},
});

export const settingsId = genUniqueId();
export const {
  useModalActions: useSettingsModalActions,
  useActiveModal: useSettingsStateModal,
  useModalProps: useSettingsModalProps,
} = generateModalHooks<SettingsModalProps>({
  init: () => ({
    ...initSettingsModalProperties,
  }),
  mixins: {},
});

export const addFlowPowerId = genUniqueId();
export const {
  useModalActions: useAddFlowPowerModalActions,
  useActiveModal: useAddFlowPowerStateModal,
} = generateModalHooks<PowerModalProps>({
  init: () => ({
    ...initPowerModalProperties,
  }),
  mixins: {},
});

export const addCombinedFlowPowerId = genUniqueId();
export const {
  useModalActions: useAddCombinedFlowPowerModalActions,
  useActiveModal: useAddCombinedFlowPowerStateModal,
} = generateModalHooks<PowerModalProps>({
  init: () => ({
    ...initPowerModalProperties,
  }),
  mixins: {},
});

export const removeFlowPowerId = genUniqueId();
export const {
  useModalActions: useRemoveFlowPowerModalActions,
  useActiveModal: useRemoveFlowPowerStateModal,
} = generateModalHooks<PowerModalProps>({
  init: () => ({
    ...initPowerModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useSendFeedbackModalActions,
  useActiveModal: useSendFeedbackStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useDeleteAlertModalActions,
  useActiveModal: useDeleteAlertStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useDeleteAppsAlertModalActions,
  useActiveModal: useDeleteAppsAlertStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useDeleteExpiredModalActions,
  useActiveModal: useDeleteExpiredStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useDeleteExpiredAppModalActions,
  useActiveModal: useDeleteExpiredAppStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useRenameDraftModalActions,
  useActiveModal: useRenameDraftStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useRenameDraftOrModeModalActions,
  useActiveModal: useRenameDraftOrModeStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useDuplicateDraftModalActions,
  useActiveModal: useDuplicateDraftStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useDeleteDraftModalActions,
  useActiveModal: useDeleteDraftStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useDeleteAppDraftModalActions,
  useActiveModal: useDeleteAppDraftStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useDeleteAppVersionModalActions,
  useActiveModal: useDeleteAppVersionStateModal,
} = generateModalHooks<DeleteAppVersionModalProps>({
  init: () => ({
    ...initDeleteAppVersionModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useDeleteVersionModeModalActions,
  useActiveModal: useDeleteVersionModeStateModal,
} = generateModalHooks<DeleteVersionModeModalProps>({
  init: () => ({
    ...initDeleteVersionModeModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useSetDefaultVersionModeModalActions,
  useActiveModal: useSetDefaultVersionModeStateModal,
} = generateModalHooks<SetDefaultVersionModeModalProps>({
  init: () => ({
    ...initSetDefaultVersionModeModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useDeleteImageModalActions,
  useActiveModal: useDeleteImageStateModal,
} = generateModalHooks<DeleteImageModalProps>({
  init: () => ({
    ...initDeleteImageModalProperties,
  }),
  mixins: {},
});

export const createAppId = genUniqueId();
export const {
  useModalActions: useCreateAppModalActions,
  useActiveModal: useCreateAppStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const {
  useModalActions: useCreateAppVersionModalActions,
  useActiveModal: useCreateAppVersionStateModal,
} = generateModalHooks<CreateAppVersionModalProps>({
  init: () => ({
    ...initCreateAppVersionModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useCopyModeToAVersionModalActions,
  useActiveModal: useCopyModeToAVersionStateModal,
} = generateModalHooks<CopyModeToAVersionModalProps>({
  init: () => ({
    ...initCopyModeToAVersionModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useChangeVersionReleaseNotesModalActions,
  useActiveModal: useChangeVersionReleaseNotesStateModal,
} = generateModalHooks<VersionReleaseNotesModeModalProps>({
  init: () => ({
    ...initVersionReleaseNotesModalProperties,
  }),
  mixins: {},
});

export const {
  useModalActions: useChangeVersionModeModalActions,
  useActiveModal: useChangeVersionModeStateModal,
} = generateModalHooks<ChangeVersionModeModalProps>({
  init: () => ({
    ...initChangeVersionModeModalProperties,
  }),
  mixins: {},
});

// export const {
//   useModalActions: useCreateVersionModeModalActions,
//   useActiveModal: useCreateVersionModeStateModal,
// } = generateModalHooks<ModalProps>({ ...emptyProperties });

export const drawerMenuId = genUniqueId();
export const {
  useModalActions: useDrawerMenuActions,
  useActiveModal: useDrawerStateMenu,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const notWhitelistedId = genUniqueId();
export const {
  useModalActions: useNotWhitelistedModalActions,
  useActiveModal: useNotWhitelistedStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const missingOnChainWalletId = genUniqueId();
export const {
  useModalActions: useMissingOnChainWalletModalActions,
  useActiveModal: useMissingOnChainWalletStateModal,
} = generateModalHooks<MissingOnChainWalletModalProps>({
  init: () => ({
    ...initMissingOnChainWalletModalProperties,
  }),
  mixins: {},
});

export const airdropId = genUniqueId();
export const {
  useModalActions: useAirdropModalActions,
  useActiveModal: useAirdropStateModal,
} = generateModalHooks<AirdropModalProps>({
  init: () => ({
    ...initAirdropModalProperties,
  }),
  mixins: {},
});

export const connectWalletModal = genUniqueId();
export const {
  useModalActions: useConnectWalletModalActions,
  useActiveModal: useConnectWalletStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const becomeABuilderId = genUniqueId();
export const {
  useModalActions: useBecomeABuilderModalActions,
  useActiveModal: useBecomeABuilderStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const subscribeToNewsletterId = genUniqueId();
export const {
  useModalActions: useSubscribeToNewsletterModalActions,
  useActiveModal: useSubscribeToNewsletterStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const registerForOnChainWalletId = genUniqueId();
export const {
  useModalActions: useRegisterForOnChainWalletModalActions,
  useActiveModal: useRegisterForOnChainWalletStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const sdkCodeId = genUniqueId();
export const {
  useModalActions: useGetSDKCodeModalActions,
  useActiveModal: useGetSDKCodeStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const flowErrorsId = genUniqueId();
export const {
  useModalActions: useFlowErrorsModalActions,
  useActiveModal: useFlowErrorsStateModal,
} = generateModalHooks<{ error?: string }>({ ...emptyProperties });

export const createAlertId = genUniqueId();
export const {
  useModalActions: useCreateAlertModalActions,
  useActiveModal: useCreateAlertStateModal,
} = generateModalHooks<CreateAlertModalProps>({
  init: () => ({
    ...initCreateAlertProperties,
  }),
  mixins: {},
});

export const dispatchedFlowAndAppModalId = genUniqueId();
export const {
  useModalActions: useDispatchedFlowAndAppModalActions,
  useActiveModal: useDispatchedFlowAndAppStateModal,
} = generateModalHooks<DispatchedFlowAndAppModalProps>({
  init: () => ({
    ...initDispatchedFlowAndAppModalProperties,
  }),
  mixins: {},
});

export const flowPowerId = genUniqueId();
export const {
  useModalActions: useFlowPowerModalActions,
  useActiveModal: useFlowPowerStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

// tests:
export const testId = genUniqueId();
export const {
  useModalActions: useTestModalActions,
  useActiveModal: useTestStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const testId2 = genUniqueId();
export const {
  useModalActions: useTestGeneratorModalActions,
  useActiveModal: useTestGeneratorStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });

export const testId3 = genUniqueId();
export const {
  useModalActions: useTestGeneratorRedModalActions,
  useActiveModal: useTestGeneratorRedStateModal,
} = generateModalHooks<ModalProps>({ ...emptyProperties });
