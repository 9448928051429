export interface MissingOnChainWalletModalProps {
  title: string;
  subtitle: string;
}

export const initMissingOnChainWalletModalProperties: MissingOnChainWalletModalProps =
  {
    title: '',
    subtitle: '',
  };
