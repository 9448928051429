/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-promise-executor-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/default-param-last */
import type {
  AllPlugins,
  Plugin,
  PluginInstance,
  PluginsModule,
} from '@kiroboio/fct-core';
import { getPluginInstanceFrom, getPluginsFromABI } from '@kiroboio/fct-core';
import type { Connection, XYPosition } from '@kiroboio/reactflow';
import type { AbiItem } from 'ethereum-abi-types-generator';
import { Interface } from 'ethers/lib/utils';
import graphlib from 'graphlib';
import _ from 'lodash';

import type { ChainIds } from '~/lib/wagmi';
import type { PluginNodeData } from '../Nodes/nodes';
import { isPluginNodeData } from '../Nodes/nodes';
import {
  createBoundaryNode,
  createNode,
  isGroupInputMarkerEdge,
} from '../Nodes/utils/createNode';

import { service } from '@kiroboio/fct-sdk';
import type {
  IUICustomPlugin,
  IUIPlugin,
  PluginType,
} from './SupportedPlugins';
import { SupportedPlugins } from './SupportedPlugins';
import {
  getFunctionCustomName,
  getFunctionsDescriptiveName,
  getProtocolCustomNames,
} from './hooks/mapper/mapper';
import { IOListPath } from './hooks/mapper/utils';
import type { FctEdge } from './FCTStore/main';
import type {
  FCTNode,
  NodeData,
  Edge,
  ProtocolName,
} from '@kiroboio/fct-builder';

export { ZERO_ADDRESS } from './constants';

const { requestAnimationFrame } = window;
export const stopAnimation = () => {
  window.requestAnimationFrame = (_callback: FrameRequestCallback) => {
    return 0;
  };
};

export const continueAnimation = () => {
  window.requestAnimationFrame = requestAnimationFrame;
};

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));
export const createEdgeFromConnection = (
  connection: Connection
): Edge & { isTargetMultiInput?: boolean } => {
  return {
    source: connection.source as string,
    sourceHandle: connection.sourceHandle,
    target: connection.target as string,
    targetHandle: connection.targetHandle,
    type: 'default',
  } as Edge & { isTargetMultiInput?: boolean };
};

export const getNodesDataMap = (nodes: FCTNode[]) => {
  return nodes?.reduce(
    (acc, node) => {
      acc[node.id] = node.data;
      return acc;
    },
    {} as Record<string, NodeData>
  );
};

export const createGraphLegacy = (
  nodes: FCTNode[] | string[] = [],
  edges: Edge[] = []
) => {
  const g = new graphlib.Graph({ multigraph: true, directed: true });
  nodes.forEach((n) => g.setNode(_.isString(n) ? n : n.id));
  edges.forEach((e) =>
    g.setEdge(
      e.source,
      e.target,
      {
        source: e.source,
        target: e.target,
        sourceHandle: e.sourceHandle,
        targetHandle: e.targetHandle,
      },
      e.id
    )
  );
  return g;
};

export const topologicalSort = (g: graphlib.Graph) => {
  return graphlib.alg.topsort(g);
};

export const isDisconnectedNode = (
  nodeId: string,
  g?: graphlib.Graph,
  filter: (g: graphlib.Graph, edge: graphlib.Edge) => boolean = (g, e) =>
    !isInnerEdge(g.edge(e))
) => {
  if (!g) return;
  const connectedEdges = (g.nodeEdges(nodeId) || []).filter((e) =>
    filter(g, e)
  );
  return connectedEdges.length === 0;
};

export const isUnreachableNode = (nodeId: string, g?: graphlib.Graph) => {
  if (!g) return;
  return !isDisconnectedNode(nodeId, g) && !isReachableNode(nodeId, g);
};

export const isReachableNode = (nodeId: string, g: graphlib.Graph) => {
  const predecessors = getPredecessors(g, nodeId);
  return predecessors.includes(START_NODE_TYPE);
};

export const getPrevConnection = (edges: Edge[], connection: Connection) =>
  edges.find(
    (e) =>
      e.source === connection.source &&
      e.sourceHandle === connection.sourceHandle
  );

export const START_NODE_TYPE = 'start';
export const END_NODE_TYPE = 'end';
export const REVERT_NODE_TYPE = 'revert';
export const VARIABLE_NODE_TYPE = 'variable';

export const isStartNode = (nodeId: string) => nodeId === START_NODE_TYPE;
export const isVariableNode = (nodeId: string) => nodeId === VARIABLE_NODE_TYPE;
export const isEndNode = (nodeId: string) => nodeId.startsWith(END_NODE_TYPE); // TODO: check for types instead of ids
export const isRevertNode = (nodeId: string) =>
  nodeId.startsWith(REVERT_NODE_TYPE);
export const isEndOrRevertNode = (nodeId: string) =>
  isEndNode(nodeId) || isRevertNode(nodeId);
export const isStartOrEndOrRevertNode = (nodeId: string) =>
  isStartNode(nodeId) || isEndOrRevertNode(nodeId);

export const createStartNode = (position: XYPosition = { x: 0, y: 0 }) =>
  createBoundaryNode({
    type: START_NODE_TYPE,
    id: START_NODE_TYPE,
    position,
  });

export const createRevertNode = (position: XYPosition = { x: 0, y: 0 }) =>
  createBoundaryNode({
    type: REVERT_NODE_TYPE,
    position,
  });

export const createEndNode = (position: XYPosition = { x: 0, y: 0 }) =>
  createBoundaryNode({
    type: END_NODE_TYPE,
    position,
  });

export const sortGroupInputs = (groupInputs: FctEdge[]) => {
  return groupInputs.sort((e1, e2) =>
    (e1.groupOrderIndex || 0) < (e2.groupOrderIndex || 0) ? -1 : 1
  );
};

export const getConnectedNodes = (
  g: graphlib.Graph,
  type: 'predecessors' | 'successors',
  nodeId: string
): string[] => {
  const connectedNodes = g[type](nodeId) || [];
  return connectedNodes.concat(
    connectedNodes.flatMap((id, i) => getConnectedNodes(g, type, id))
  );
};

export const getPredecessors = (
  g?: graphlib.Graph,
  nodeId?: string
): string[] => {
  if (!g) return [];
  if (!nodeId) return [];
  return getConnectedNodes(g, 'predecessors', nodeId);
};
export const getSuccessors = (
  g?: graphlib.Graph,
  nodeId?: string
): string[] => {
  if (!g) return [];
  if (!nodeId) return [];
  return getConnectedNodes(g, 'successors', nodeId);
};
export const isReactFlowPane = (element: EventTarget | null) => {
  if (!element) return false;
  return (element as HTMLElement).classList.contains('react-flow__pane');
};

export const setBooleans = <T extends Parameters<typeof _.mapValues>['0']>(
  obj: T,
  bool: boolean
) => {
  if (typeof obj !== 'object') return obj;
  return _.mapValues(obj, (value, key) => {
    return typeof value === 'boolean' ? bool : value;
  });
};

export const createNodeData = (
  plugin: AllPlugins,
  chainId: ChainIds,
  id?: string
) => {
  if (chainId === '0') return;
  const json = new plugin({
    chainId: chainId as any,
  }).toJSON();
  const pluginNode = JSON.parse(json);
  pluginNode.id = id;
  return pluginNode;
};

export const createNodeDataModule = (
  plugin: PluginsModule,
  chainId: ChainIds,
  id?: string
) => {
  if (chainId === '0') return;
  const pluginModuleInterface = plugin.getInterface({
    chainId: chainId as any,
  });
  if (!pluginModuleInterface) return;
  const pluginModuleNode = JSON.parse(pluginModuleInterface.instance.toJSON());

  // pluginModuleNode.id = v4();
  pluginModuleNode.moduleId = id;
  pluginModuleNode.moduleData = pluginModuleInterface.moduleInstance.toJSON();
  return pluginModuleNode;
};

export const createNodeType = (instance: {
  protocol: string;
  name: string;
}) => {
  return `${instance.protocol}-${instance.name}`.toLocaleLowerCase();
};

export const createNodeFromPlugin = (
  position: XYPosition,
  plugin: AllPlugins,
  chainId: ChainIds,
  id?: string
) => {
  if (chainId === '0') return;
  const instance = new plugin({
    chainId: chainId as any,
  }) as unknown as PluginInstance;
  const data = createNodeData(plugin, chainId, id);
  if (data?.type === 'MODULE') {
    return createNode({
      position,
      data,
      type: 'MODULE',
      id: data.id,
    });
  }
  return createNode({
    position,
    data,
    type: createNodeType(instance),
  });
};

export const mapParamType = (obj: Record<string, unknown>, type: string) => {
  return _.mapValues(obj, () => type);
};

export const createIOTypesFromInstance = (instance: _PluginInstance) => {
  const params = mapParamType(
    _.omit(instance.input.params, 'methodParams'),
    'input'
  );
  const methodParams = mapParamType(
    instance.input.params.methodParams,
    'input'
  );
  return {
    input: {
      params: {
        ...params,
        methodParams,
      },
    },
    output: {
      params: mapParamType(instance.output.params, 'output'),
    },
  };
};

// type Plugin = (typeof plugins)[number]['details']['plugin'];
// type PluginInstance = (typeof plugins)[number]['instance'];
export type _Plugin = Plugin;
export type _PluginInstance = PluginInstance;

export const PUBLISH_BLOCKING_QUERY_KEY = 'fct-builder';

export const createFunctionPath = (
  functionNodeData: Pick<PluginNodeData, 'protocol' | 'name'>
) => {
  return `${functionNodeData.protocol}.names.${functionNodeData.name}`;
};

export class PluginValuesUpdates {
  public static updatePluginBy = async ({ nodeId }: { nodeId: string }) => {
    await sleep(1000);
    const observe = this.observers.get(nodeId);
    if (observe) observe();
  };

  public static subscribe = ({
    callback,
    id,
  }: {
    callback: () => void;
    id: string;
  }) => {
    this.observers.set(id, callback);
  };

  public static unsubscribe = ({ id }: { id: string }) => {
    this.observers.delete(id);
  };

  private static observers: Map<string, () => void> = new Map();
}

export const groupedCustomPluginAndMethodNames = () =>
  Object.values(SupportedPlugins.groupedPlugins)
    .map((p) => {
      return p.map((p) => {
        const key = createFunctionPath(p.instance);
        return {
          methodName: getFunctionsDescriptiveName(key) || p.instance.name,
          method: p.instance.method,
          customMethodName:
            getFunctionCustomName(
              p.instance.protocol as ProtocolName,
              p.instance.name
            ) || p.instance.method,
          ...getProtocolCustomNames(p.instance.protocol),
          protocol: p.instance.protocol,
          methodInterfaceHash: p.instance.methodInterfaceHash,
          name: p.instance.name,
        };
      });
    })
    .flat();

export const findPlugin = (protocol: string, name: string, id?: string) => {
  return _.find(SupportedPlugins.all, {
    id,
    instance: {
      protocol,
      name,
    },
  }) as unknown as IUIPlugin | IUICustomPlugin;
};

export const tryToCreatePluginFrom = (
  nodeData: PluginNodeData,
  chainId: ChainIds
) => {
  if (chainId === '0') return;
  try {
    if (!nodeData.protocol) {
      throw new Error('wrong protocol');
    }
    if (!nodeData.contractInterface) {
      throw new Error('wrong contractInterface');
    }
    const pluginInterface = new Interface([
      `function ${nodeData.contractInterface} ${
        nodeData.type === 'GETTER' ? 'view' : 'payable'
      } ${nodeData.contractInterfaceReturns}`,
    ]);
    const abi = Object.keys(pluginInterface.functions).map(
      (key) => pluginInterface.functions[key]
    ) as AbiItem[];
    if (!abi) {
      throw new Error(
        `Abi creation for plugin ${nodeData.protocol} ${nodeData.contractInterface} failed`
      );
    }
    const inputTo = nodeData.input.to as string | { value: string };
    const to = typeof inputTo === 'string' ? inputTo : inputTo.value;
    if (typeof to !== 'string') {
      throw new Error(
        `Address "to" for ${nodeData.protocol} ${nodeData.contractInterface} not a string`
      );
    }
    const { plugins } = getPluginsFromABI({
      abi,
      protocol: nodeData.protocol,
      contractAddress: to,
      chainId: chainId as any,
      pluginName: nodeData.name,
    });
    if (!plugins || !plugins.length) {
      throw new Error(
        `Plugin creation for ${nodeData.protocol} ${nodeData.contractInterface} failed`
      );
    }
    return {
      instance: new plugins[0].plugin({
        chainId: chainId as any,
        initParams: nodeData.input as any,
      }) as unknown as PluginInstance,
      details: plugins[0],
    };
  } catch (e) {
    console.error({ fallbackPluginCreationError: e });
  }
};

const cache: Map<string, PluginInstance> = new Map();
export const createPluginInstanceFromNodeData = (
  nodeData: NodeData,
  chainId: ChainIds,
  walletAddress: string,
  vaultAddress: string,
  id?: string
) => {
  if (chainId === '0') return;
  if (!nodeData || !isPluginNodeData(nodeData)) return;

  const plugin = findPlugin(
    nodeData.protocol,
    nodeData.name,
    nodeData.pluginType === 'DEFAULT' ? undefined : nodeData.id
  );

  if (!plugin) {
    const json = JSON.stringify(nodeData) as ReturnType<
      PluginInstance['toJSON']
    >;
    const customPlugin = getPluginInstanceFrom({
      json,
      chainId: chainId as any,
    });
    if (!customPlugin) {
      return;
    }
    if (customPlugin) {
      const customPluginInstance = customPlugin.instance;
      customPluginInstance.fromJSON(JSON.stringify(nodeData));
      return {
        type: nodeData.pluginType || 'CUSTOM',
        instance: customPluginInstance,
      } as const;
    }
  }

  let instance: PluginInstance;
  const prevInstance = id ? cache.get(id) : undefined;
  if (prevInstance) {
    instance = prevInstance;
  } else {
    const provider = service.providers.smartRpc();
    instance = new plugin.details.plugin({
      chainId: chainId as any,
      walletAddress,
      vaultAddress,
      provider,
    }) as unknown as PluginInstance;
    if (id) cache.set(id, instance);
    instance.fromJSON(JSON.stringify(nodeData));
  }
  nodeData.pluginType = plugin.type;
  return { type: plugin.type, instance };
};

export const canBePlugin = ({
  nodeData,
  chainId,
  walletAddress,
  vaultAddress,
  onTrue,
  id,
}: {
  nodeData: NodeData;
  chainId: ChainIds;
  walletAddress: string;
  vaultAddress: string;
  onTrue?: (p: {
    instance: PluginInstance;
    type: PluginType;
    moduleInstance?: PluginsModule;
  }) => void;
  id?: string;
}) => {
  if (!isPluginNodeData(nodeData)) return false;
  const plugin = createPluginInstanceFromNodeData(
    nodeData,
    chainId,
    walletAddress,
    vaultAddress
  );
  if (!plugin) return;
  const { instance, type } = plugin;
  if (instance)
    onTrue?.({
      instance: instance as PluginInstance,
      type: type as PluginType,
    });
  return Boolean(instance);
};

export const getValidNodes = (nodes: FCTNode[], chainId: ChainIds) => {
  return nodes.filter(
    (n) => isStartOrEndOrRevertNode(n.id) || n.type === 'variable'
  );
};

export enum HandleID {
  Success = 'success',
  Fail = 'fail',
  In = 'in',
  Flow = 'flow',
}
export const FlowHandleIDs = Object.values(HandleID);

enum InnerHandleDir {
  Out = 'out',
  In = 'in',
}

export const createOuterParamPath = (key: string, isMethodParam = false) => {
  if (isMethodParam) return `input.params.methodParams.${key}`;
  return `input.params.${key}`;
};

export const createOuterMethodParamPath = (key: string) => {
  return `input.params.methodParams.${key}`;
};

export const createOuterOutputParamPath = (key: string) => {
  return `output.params.${key}`;
};

export const getInnerPath = (path: string) => {
  // remove input.params or output.params from path with regex
  return path.replace(/(input|output)\.params\./, '');
};

export const createInnerHandleId = (path: string, type: `${InnerHandleDir}`) =>
  `inner-${type}-${path}`;

export const createInHandleId = (key: string) => {
  return createInnerHandleId(key, InnerHandleDir.In);
};

export const createOutHandleId = (key: string) => {
  return createInnerHandleId(key, InnerHandleDir.Out);
};

export const removeParamStrFromPath = (path: string) => {
  return path.replace('.params.', '.');
};

export const createParamHandleId = (
  key: string,
  isMethodParam = false,
  dir: `${InnerHandleDir}`
) => createInnerHandleId(createOuterParamPath(key, isMethodParam), 'in');

export const createParamInHandleId = (key: string) =>
  createParamHandleId(key, false, 'in');
export const createParamOutHandleId = (key: string) =>
  createParamHandleId(key, false, 'out');
export const createMethodParamInHandleId = (key: string) =>
  createParamHandleId(key, true, 'in');
export const createMethodParamOutHandleId = (key: string) =>
  createParamHandleId(key, true, 'out');

export const createOutputParamHandleId = (key: string) =>
  createInnerHandleId(createOuterOutputParamPath(key), 'out');

export const createInputHandleId = (index: number) => {
  return `input|${index}`;
};

export const createOutputHandleId = (index: number) => {
  return `output|${index}`;
};

const PARAMETER_PATH_REG = /^(?:(input|output))(WithMeta)/;

export const isParameterPath = (path: string | null | undefined) => {
  if (!path) return false;
  return PARAMETER_PATH_REG.test(path);
};

export const parseParameterPath = (path: string) => {
  const [type, ...inputPath] = path.split('.');
  if (
    !type &&
    ![IOListPath.input, IOListPath.output].includes(type as IOListPath)
  )
    return;
  return {
    type: (type === IOListPath.input ? 'input' : 'output') as
      | 'input'
      | 'output',
    outerPath: path,
    inputPath: inputPath.join('.'),
  };
};

export const isInnerHandle = (handleId?: string | null) => {
  return /inner/.test(handleId || '');
};

export const isFlowHandle = (handleId?: string | null) => {
  if (!handleId) return false;
  return (FlowHandleIDs as string[]).includes(handleId);
};

export const isFlowEdge = (edge: Edge) => {
  return isFlowHandle(edge.sourceHandle) && isFlowHandle(edge.targetHandle);
};

export const isInputOrOutputHandle = (handleId?: string | null) => {
  return /input|output/.test(handleId || '');
};

export const isInnerEdge = (edge: Edge) => {
  return isInnerHandle(edge.sourceHandle) || isInnerHandle(edge.targetHandle);
};

// function to remove local-in- and local-out- and output-out- from the handle ids
export const getPathFromHandleId = (handleId?: string | null) => {
  if (!handleId) return '';
  return handleId.replace(/inner-(in|out)-/, '');
};

export const generateDragData = (
  plugin: AllPlugins,
  chainId: ChainIds,
  id?: string
) => {
  return JSON.stringify(createNodeData(plugin, chainId, id));
};

export const generateDragDataModule = (
  plugin: PluginsModule,
  chainId: ChainIds,
  id?: string
) => {
  return JSON.stringify(createNodeDataModule(plugin, chainId, id));
};

export const getDragData = (json: string): NodeData | null => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

export const DRAG_FCT_PLUGIN_DATA = 'fct/plugin';
export const DRAG_FCT_START_END_REVERT = 'fct/start-end-revert';
export const DRAG_FCT_VARIABLE = 'fct/variable';

export const NO_DROP_CLASS = 'no-drop';
export const canDropOn = (element: Element) => {
  return !element.classList.contains(NO_DROP_CLASS);
};

export const getGitbookLink = ({
  protocol,
  type,
  method,
}: {
  protocol: string;
  type: string;
  method: string;
}) => {
  const protocolMapper: Record<string, string> = {
    validator: 'purevalidator',
    math: 'puresafemath',
    aave: 'aave-v2',
  };

  const typeMapper: Record<string, string> = {
    action: 'actions',
    getter: 'getters',
    calculator: 'calculate',
    validator: 'validate',
  };

  const p =
    protocolMapper[protocol.toLocaleLowerCase()] ||
    protocol.toLocaleLowerCase();
  const t = typeMapper[type.toLocaleLowerCase()] || type.toLocaleLowerCase();
  const m = method.toLocaleLowerCase() || 'sendeth';

  return `https://kirobo.gitbook.io/fct-plugins/protocols/${p}/${t}/${m}`;
};

export const isPluginNode = (node: FCTNode) => {
  return (
    Boolean(node.data) &&
    !isVariableNode(node.type as string) &&
    !isStartNode(node.type as string) &&
    !isRevertNode(node.type as string) &&
    !isEndNode(node.type as string)
  );
};

export const filterPluginNodes = (nodes: FCTNode[]) => {
  return nodes.filter(isPluginNode);
};

export const getIntentIOType = (edge?: Edge) => {
  if (!edge) return;
  if (edge.intentIOType) return edge.intentIOType as 'INPUT' | 'OUTPUT';
  if (isGroupInputMarkerEdge(edge)) return 'INPUT';

  return 'OUTPUT';
};

// const getValueFromPath = (obj: any, path: string) => {
//   const pathArray = _.toPath(path);
//   return pathArray.reduce((acc, part) => {
//     const isNegativeNumber = /^-\d+$/.test(part);
//     if (isNegativeNumber) {
//       // get the absolute value of the number
//       const absoluteNumber = Math.abs(Number(part));
//       // get the value from the end of the array
//       return acc[acc.length - absoluteNumber];
//     }
//     return acc[part];
//   }, obj);
// };
