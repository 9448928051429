interface BaseModalProps {
  account: 'vault' | 'wallet';
  to: string;
  amount: string;
  max: boolean;
  betweenWallets: boolean;
}

export interface SendTokenModalProps extends BaseModalProps {
  amountUsd: string;
}

export interface SendNftModalProps extends BaseModalProps {
  showDetails: boolean;
}

const initBaseProperties: BaseModalProps = {
  account: 'vault',
  to: '',
  amount: '',
  max: false,
  betweenWallets: true,
};

export const initSendTokenModalProperties = {
  ...initBaseProperties,
  amountUsd: '',
};

export const initSendNftModalProperties = {
  ...initBaseProperties,
  showDetails: false,
};
