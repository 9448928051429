import type { Connection, Edge } from '@kiroboio/reactflow';

export enum IOListPath {
  input = 'inputWithMeta',
  output = 'outputWithMeta',
}
export const ioListPathReg = new RegExp(
  `^${IOListPath.input}|${IOListPath.output}`
);

export const createInputParameterPath = (
  path: string,
  index?: number | string
) => {
  const parameterPath = `${IOListPath.input}.${path}`;
  if (index === undefined) return parameterPath;
  return `${parameterPath}_${index}`;
};

export const isParameterHandleId = (id: Edge['sourceHandle']) => {
  if (!id) return false;
  return ioListPathReg.test(id);
};

export const isLeafEdge = (edge: Pick<Edge, keyof Connection>) => {
  return edge.sourceHandle === 'flow';
};

export const isParameterEdge = (edge: Pick<Edge, keyof Connection>) => {
  return (
    isParameterHandleId(edge.sourceHandle) &&
    isParameterHandleId(edge.targetHandle)
  );
};

export const createOutputParameterPath = (path: string) => {
  return `${IOListPath.output}.${path}`;
};
