import { merge } from 'lodash';
import { proxy, useSnapshot } from 'valtio';

export const generateModalHooks = <
  PROPS,
  MIXIN extends (prev: PROPS) => PROPS = (prev: PROPS) => PROPS,
>({
  init,
  mixins,
}: {
  init: () => PROPS;
  mixins: Record<string, () => MIXIN>;
}) => {
  const modal = proxy({ id: '', isOpen: false });
  const data: Record<string, { props: PROPS }> = {};
  const getProxy = (id: string) => {
    if (!data[id]) {
      data[id] = proxy({ props: init() });
    }
    return data[id];
  };
  const getIsOpen = () => {
    return modal.isOpen;
  };

  const open = (
    id: string,
    patch: Partial<PROPS> | ((prev: PROPS) => PROPS)
  ) => {
    modal.id = id;
    modal.isOpen = true;
    const prev = getProxy(id);

    prev.props =
      typeof patch === 'function'
        ? patch(merge(init(), prev.props))
        : merge(prev.props, init(), patch);
  };
  const clear = (id: string) => {
    getProxy(id).props = init();
  };
  const close = (options?: { clear?: boolean; clearId?: boolean }) => {
    if (options?.clear) {
      clear(modal.id);
    }
    if (!options || options?.clearId === undefined || options?.clearId) {
      modal.id = '';
    }
    modal.isOpen = false;
  };
  const mutate = (id: string, patch: (prev: PROPS) => PROPS) => {
    const item = getProxy(id);
    item.props = patch(item.props);
  };
  const useModalProps = (id: string) => {
    const itemSnap = useSnapshot(getProxy(id));
    return {
      ...(itemSnap.props as any),
    };
  };
  const useModalActions = (id: string) => {
    const mutateMixin = mutate.bind(null, id);
    const mixin = Object.entries(mixins).reduce(
      (prev, cur) => ({ ...prev, [cur[0]]: () => mutateMixin(cur[1]()) }),
      {}
    ) as typeof mixins;
    return {
      mixins: mixin,
      mutate: mutate.bind(null, id),
      clear: clear.bind(null, id),
      /**
       * Set dynamic props here! `open({ ...dynamicProps, handler, ...})`
       */
      open: open.bind(null, id),
      close,
      getIsOpen,
    };
  };
  const useActiveModal = () => {
    const modalSnap = useSnapshot(modal);
    return {
      props: useModalProps(modalSnap.id),
      ...useModalActions(modalSnap.id),
      openById: open,
      mutateById: mutate,
      clearById: clear,
      ...modalSnap,
    };
  };

  return {
    useModalProps,
    useModalActions,
    useActiveModal,
  };
};
