export type AlertSocialType =
  | {
      type: 'telegram';
      value: string;
      active: boolean;
    }
  | {
      type: 'webhook';
      value: string;
      active: boolean;
    };

export interface AlertMeta {
  description: string;
  social: AlertSocialType[];
  success_notice: string;
  fail_notice: string;
}

export interface EditAlertModalProps extends AlertMeta {}

export const initEditAlertModalProperties: EditAlertModalProps = {
  description: '',
  social: [],
  success_notice: '',
  fail_notice: '',
};
