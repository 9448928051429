import type { ChainIds } from '~/lib/wagmi';

export const ignorePlugins = [
  { protocol: 'ERC20', method: 'name' },
  { protocol: 'ERC20', method: 'symbol' },
  { protocol: 'ERC20', method: 'decimals' },
  { protocol: 'ERC20', method: 'totalSupply' },
  { protocol: 'ERC20', method: 'transfer' },
  { protocol: 'ERC20', method: 'transferFrom' },
  { protocol: 'UNISWAP', method: 'simpleSwap' },
  { protocol: 'UNISWAP', method: 'swapETHForExactTokens' },
  { protocol: 'UNISWAP', method: 'swapExactETHForTokens' },
  { protocol: 'UNISWAP', method: 'swapExactTokensForETH' },
  { protocol: 'UNISWAP', method: 'swapTokensForExactETH' },
  { protocol: 'UNISWAP', method: 'swapExactTokensForTokens' },
  { protocol: 'UNISWAP', method: 'swapTokensForExactTokens' },
  { protocol: 'UNISWAP', method: 'getAmountsIn' },
  { protocol: 'UNISWAP', method: 'getAmountsOut' },
  { protocol: 'UNISWAP', method: 'addLiquidityETH' },
  { protocol: 'UNISWAP', method: 'removeLiquidity' },
  { protocol: 'UNISWAP', method: 'removeLiquidityETH' },
  { protocol: 'CHAINLINK', method: 'decimals' },
  { protocol: 'AAVE', method: 'getReserveTokensAddresses' },
  { protocol: 'AAVE', method: 'getReserveConfigurationData' },
  { protocol: 'AAVE', method: 'flashLoan' },
  { protocol: 'AAVE', method: 'swapBorrowRateMode' },
  { protocol: 'CURVE', method: 'get_best_rate' },
];

export const supportedProtocols = [
  'SECURE_STORAGE',
  'MAGIC',
  'ERC20',
  'ERC721',
  'ERC1155',
  'UNISWAP',
  'UNISWAP_V3',
  'UTILITY',
  'TOKEN_MATH',
  'TOKEN_VALIDATOR',
  'AAVE',
  'AaveV3',
  'CHAINLINK',
  'FUNCTIONS',
  'GMX',
  '1INCH',
  'COMPOUND_V2',
  'COMPOUND_V3',
  'ROCKETPOOL',
  'CURVE',
  'YEARN',
  'KIROBO_MULTICALL_CSV',
  'cBridge',
  'SingersValidator',
];

export const ignoreProtocols: Record<ChainIds | '10', string[]> = {
  '0': supportedProtocols,
  '1': ['ERC721', 'ERC1155', 'GMX'],
  '10': ['ERC721', 'ERC1155', 'GMX'],
  '8453': [
    'ERC721',
    'ERC1155',
    'AAVE',
    'GMX',
    'ROCKETPOOL',
    'COMPOUND_V2',
    'MAGIC',
  ],
  '42161': [
    'ERC721',
    'ERC1155',
    'UNISWAP',
    'AAVE',
    'GMX',
    'ROCKETPOOL',
    'COMPOUND_V2',
    'MAGIC',
  ],
  '11155111': ['ERC721', 'ERC1155', 'cBridge'],
};

export const pickPluginsFromIngonredProtocols = [
  { protocol: 'ERC721', method: 'approve' },
  { protocol: 'ERC721', method: 'balanceOf' },
  { protocol: 'ERC1155', method: 'setApprovalForAll' },
  { protocol: 'ERC1155', method: 'balanceOf' },
  { protocol: 'ERC721', method: 'safeTransferFrom' },
  { protocol: 'ERC1155', method: 'safeTransferFrom' },
];

export const pickByNamePlugins = [
  {
    protocol: 'ERC20',
    name: 'simpleTransfer',
    chainId: ['1', '10', '8453', '42161', '11155111'],
  },
  {
    protocol: 'GMX',
    name: 'closePositionMulticall',
    chainId: ['42161'],
  },
  { protocol: '1INCH', name: 'simpleSwap1Inch', chainId: ['1', '42161'] },
  {
    protocol: 'UNISWAP',
    name: 'simpleSwapV2',
    chainId: ['1', '10', '42161', '8453', '11155111'],
  },
  {
    protocol: 'UNISWAP',
    name: 'simpleSwapExactInputWithFeeV2',
    chainId: ['1', '10', '42161', '8453', '11155111'],
  },
];

export const ignoreByNamePlugins = [
  { protocol: 'CURVE', name: 'createCurveTokenLock', chainId: ['42161'] },
  {
    protocol: 'CURVE',
    name: 'increaseCurveTokenLockAmount',
    chainId: ['42161'],
  },
  { protocol: 'CURVE', name: 'increaseCurveTokenLock', chainId: ['42161'] },
  { protocol: 'CURVE', name: 'withdrawCurveToken', chainId: ['42161'] },
  { protocol: 'CURVE', name: 'lockedCurveTokenAmount', chainId: ['42161'] },
  { protocol: 'CURVE', name: 'get_best_rate', chainId: ['42161'] },
  {
    protocol: 'UNISWAP_V3',
    name: 'exactInput',
    chainId: ['1', '10', '42161', '8453', '11155111'],
  },
  // {
  //   protocol: 'FUNCTIONS',
  //   name: 'reflow',
  //   chainId: ['1', '10', '42161', '8453', '11155111'],
  // },
  {
    protocol: 'UNISWAP_V3',
    name: 'snipper',
    chainId: ['1', '10', '42161', '8453'],
  },
  {
    protocol: 'UNISWAP_V3',
    name: 'simpleSwapExactInputV3',
    chainId: ['10', '8453'],
  },
  {
    protocol: 'UNISWAP_V3',
    name: 'simpleSwapExactOutputV3',
    chainId: ['10', '8453'],
  },
  {
    protocol: 'UNISWAP_V3',
    name: 'snipper_swap_router_02',
    chainId: ['1', '42161', '11155111'],
  },
  {
    protocol: 'UNISWAP_V3',
    name: 'simpleSwapExactInputV3SwapRouter02',
    chainId: ['1', '42161', '11155111'],
  },
  {
    protocol: 'UNISWAP_V3',
    name: 'simpleSwapExactOutputV3SwapRouter02',
    chainId: ['1', '42161', '11155111'],
  },
  {
    protocol: 'FUNCTIONS',
    name: 'getOffchainDataV2',
    chainId: ['1', '42161', '8453', '11155111'],
  },
];
