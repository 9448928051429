import type { VersionDraftFindResponseDto } from '@kiroboio/fct-sdk/service/types/versions-drafts.dto';
import type { DeepReadonly } from '@kiroboio/fct-sdk/utils/data';

export interface CopyModeToAVersionModalProps {
  name: string;
  description: string;
  draft: any;
  appDraftId: string;
  versionList: DeepReadonly<VersionDraftFindResponseDto[]>;
}

export const initCopyModeToAVersionModalProperties: CopyModeToAVersionModalProps =
  {
    name: '',
    description: '',
    draft: {},
    appDraftId: '',
    versionList: [],
  };
