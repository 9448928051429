/* eslint-disable @typescript-eslint/naming-convention */
import type { Connection, Edge, Node } from '@kiroboio/reactflow';
import { v4 as uuid } from 'uuid';

import type {} from '../nodes';
import type { FCTNode, NodeData } from '@kiroboio/fct-builder';

type CreateNodeParams = {
  position?: FCTNode['position'];
  id?: string;
  data: NodeData;
  type: string;
};

type CreateGroupIOMarkerParams = {
  position?: FCTNode['position'];
  groupId: string;
  type: 'input' | 'output';
};

interface createBoundaryNodeParams {
  position?: FCTNode['position'];
  id?: string;
  type: 'start' | 'revert' | 'end';
}

type CreateNode = (params: CreateNodeParams) => FCTNode;

type CreateGroupIOMarker = (params: CreateGroupIOMarkerParams) => FCTNode;

type CreateBoundaryNode = (params: createBoundaryNodeParams) => Node;

export const createNode: CreateNode = ({
  position = { x: 0, y: 0 },
  id,
  data,
  type,
}) => {
  const getNodeId = () => {
    if (id) return id;
    const uniqueId = uuid();
    if (type === 'end' || type === 'revert') {
      return `${type}${uniqueId}`;
    }

    return uniqueId;
  };

  return {
    type,
    id: getNodeId(),
    data: data || {},
    position,
  };
};

export const groupIdReg = /^g\|/;
export const isGroupId = (id: string) => groupIdReg.test(id);

export const groupMarkerIdReg = /^g-(input|output)-marker\|/;
export const isGroupMarkerId = (id?: string | null) => {
  if (!id) return false;
  return groupMarkerIdReg.test(id);
};
export const isGroupMarkerEdge = (edge: Pick<Edge, keyof Connection>) =>
  isGroupMarkerId(edge.source) || isGroupMarkerId(edge.target);

export const isGroupInputMarkerEdge = (edge: Pick<Edge, keyof Connection>) =>
  isGroupMarkerId(edge.source);

export const isGroupOutputMarkerEdge = (edge: Pick<Edge, keyof Connection>) =>
  isGroupMarkerId(edge.target);

export const getParameterDetailsByMarkerEdge = (
  edge: Pick<Edge, keyof Connection>
) => {
  if (!isGroupMarkerEdge(edge)) return null;
  const isInputMarkerEdge = isGroupInputMarkerEdge(edge);
  return {
    nodeId: isInputMarkerEdge ? edge.target : edge.source,
    path: isInputMarkerEdge ? edge.targetHandle : edge.sourceHandle,
  };
};

export const createGroupIOMarkerId = (
  groupId: string,
  type: 'input' | 'output'
) => {
  return `g-${type}-marker|${groupId}`;
};

export const createGroupIOMarker: CreateGroupIOMarker = ({
  position = { x: 0, y: 0 },
  groupId,
  type,
}) => {
  return {
    type: `function-group-${type}-marker`,
    id: createGroupIOMarkerId(groupId, type),
    data: {
      groupId,
    },
    deletable: false,
    position,
  };
};

export const createBoundaryNode: CreateBoundaryNode = ({
  position = { x: 0, y: 0 },
  id,
  type,
}) => {
  return {
    type,
    id: id || type + uuid(),
    position,
    data: { protocol: type, type, method: type, name: type },
    instance: { protocol: type, type, method: type, name: type },
  } as Node;
};
